import React from "react";

import Section from "../Section/Section";
import './Career.css';
import {useTranslation} from "react-i18next";
import CareerCard from "../CareerCard/CareerCard";
import {Tabs} from "../Tabs/Tabs";
import {TabContent} from "react-bootstrap";

export default function Career({techStack}) {
    const { t } = useTranslation();
    return (
        <Section title="Work Experience">
            <p>
                I possess an extensive skill set that enables me to develop end-to-end applications —from gathering requirements, planning and designing the architecture, to programming, testing, and deployment. My strength lies in back-end development, but I am also proficient in front-end development.
            </p>

            <ul className={"career-grid"}>
                <Tabs>
                    <TabContent label={"Avalith"}>
                        <CareerCard
                            title="Senior Backend Engineer @ Avalith"
                            lightText="Remote | jul 2022 - now"
                            usedTechStack={['PHP 8', 'Slim', 'Docker', 'PHPUnit', 'Behat', 'Doctrine', 'Redis', 'Elasticsearch', 'Swagger', 'New Relic', 'JavaScript', 'React']}
                            techStack={techStack}
                            description="<strong>Working at a Massive streaming platform</strong><br>
                        <ul class=arrow-list>
                          <li>Collaborated on architectural decisions, code reviews, and best practices, applied clean code principles.</li>
                          <li>Migrated microservices to Hexagonal Architecture in Laravel, leveraging event-driven design.</li>
                          <li>Implemented observability with New Relic APM to ensure system health and performance.</li>
                          <li>Setup CI/CD pipelines with GitHub Actions.</li>
                          <li>Ensured robust TDD coverage with unit, integration, and acceptance tests.</li>
                          <li>Engineered new features to enhance platform capabilities, providing critical executive-level insights.</li>
                          <li>Mentored team members on hexagonal architecture, DDD, EDA, testing strategies, repository pattern, and ORM tools like Doctrine.</li>
                          <li>Documented APIs using Swagger (OAS 3.0).</li>
                          <li>Improved Docker build efficiency and optimized makefiles.</li>
                          <li>Led trainees and juniors, aligning their growth with team goals.</li>
                          <li>Gave multiple talks on ergonomics and remote work setups to teams of thirty.</li>
                        </ul>


                        <h4>Grows (in-company courses):</h4>
                        <ul class=arrow-list>
                            <li>ReactJS</li>
                            <li>NodeJS</li>
                            <li>Swagger (OAS 3.0)</li>
                            <li>SCRUM/JIRA</li>
                        </ul>"
                        />
                    </TabContent>
                    <TabContent label={"Symbar"}>
                        <CareerCard
                            title="Intermediate Software Engineer @ Symbar (Grupo Hasar)"
                            lightText="Remote | nov 2021 - ago 2022"
                            usedTechStack={['PHP 8', 'Slim', 'MySQL', 'Doctrine', 'PHPUnit', 'jQuery', 'JavaScript']}
                            techStack={techStack}
                            description="
                                <ul class=arrow-list>
                                  <li>Developed core backend features for Suticket.com using PHP 8, Slim, and Doctrine with DDD and Hexagonal Architecture.</li>
                                  <li>Implemented complex algorithms for seat selection and payment processing (Plexo).</li>
                                  <li>Built CRUD dashboards with IoT device monitoring (Apache Mosquitto), including SMS and email alerts with GPS data for anomalies.</li>
                                  <li>Applied heuristics to reach an optimized solution for the Traveling Salesman used in e-commerce logistics routes for multiple vehicles with Docker, VROOM, OSR/ORSM and Maps APIs.</li>
                                </ul>
                            "
                        />
                    </TabContent>


                    <TabContent label={"Incluyeme.com"}>
                        <CareerCard
                            title="Software Engineer @ Incluyeme.com"
                            lightText="Hybrid | feb 2019 - oct 2019"
                            usedTechStack={['PHP 8', 'MySQL', 'Elasticsearch', 'AWS', 'WordPress', 'JavaScript']}
                            techStack={techStack}
                            description="
                                <ul class=arrow-list>
                                  <li>Coded a suite of internal tools and WordPress plugins to increase recruiter efficiency, enabling millisecond-level candidate searches across millions of records with filters and segmentation using a custom UI and Elasticsearch as the core.</li>
                                  <li>Integrated Elasticsearch, AWS Comprehend, and Textract to enhance search and document processing capabilities.</li>
                                  <li>Synchronized millions of existing records to Elasticsearch on low-spec startup servers.</li>
                                  <li>Enhanced accessibility across Latin American websites, ensuring platform usability for individuals with disabilities.</li>
                                </ul>
                            "
                        />
                    </TabContent>
                </Tabs>
            </ul>

        </Section>
    );
}