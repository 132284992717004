import React from "react";
import Section from "../Section/Section";

export default function MyBeginnings(props) {
    return (
        <Section title="My beginnings">
            <p>
                I began playing with computers when I was around 4 years old, enjoying the great classic games of that time, and I kept learning how to fix any computer that came my way (I ended up breaking 7 PCs). I was always fascinated by the technical side of things.
            </p>

            <p>
                My interest in computer security and web design started when I was 8, after hearing my mother mention some "hackers." Without delay, I began searching for and learning about web design using platforms and software like wix.com, webs.com, foroactivo.com, jimdo.com, SMF, Spirate, Website X5, Dreamweaver, and Frontpage. At that time, I also played MMORPGs and FPS games, and since clan forums were trending, I learned how to create forums, search for and download scripts, find web hosting, upload files via FTP, create and configure databases, set permissions, and install them—all at just 10 years old.
            </p>

            <p>
                During Taringa!'s heyday, it was fashionable to create clones of that link sharing system (now a social network). This is where I learned basic HTML, CSS, and PHP skills, becoming proficient at modifying themes and uploading them to production.
            </p>

            <p>
                When I was 14, I watched the movie "The Social Network," which inspired me to seriously start learning HTML5, CSS, JavaScript, and, most importantly, PHP with MySQL—with the goal of creating a truly ambitious project (which is now obsolete and poorly developed): an improved 2004 Facebook clone.
            </p>

            <p>
                Another ambitious project was Brax, a complex SaaS platform for game hosting that kept me entertained for several years.
            </p>
        </Section>
    );
}