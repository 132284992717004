import React from 'react';
import './Tabs.css';

export const Tab = ({ active, label, onClick }) => (
    <div
        className={`tab ${active ? 'active' : ''}`}
        onClick={onClick}
    >
        {label}
    </div>
);


