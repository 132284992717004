import React from 'react';

import StrongTitleEmdash from '../StrongTitleEmdash/StrongTitleEmdash';

import './Skills.css';
import { SkillsList } from "../SkillsList/SkillsList";
import {useTranslation} from "react-i18next";

export default function Skills({techStack, includes}) {
    const { t } = useTranslation();
    return (
        <section className="skills">
            <StrongTitleEmdash>{t('Technologies')}</StrongTitleEmdash>
            <p>{t('I only add the technologies that I have actually used in real, serious projects.')}</p>
            <div>
                <SkillsList techStack={techStack} includes={includes}/>
            </div>
        </section>

    );
}