import React from 'react';

import Section from '../Section/Section';
import './Projects.css';
import {useTranslation} from "react-i18next";

export const Projects = ({children}) => {
    const { t } = useTranslation();
    return <Section title="Projects">
        <h3>{t('Personal & Freelance projects')}</h3>


        <div className={'projects'}>
            {children}
        </div>
    </Section>;
}