import React from 'react';
import { SkillsList } from "../SkillsList/SkillsList";
import Carousel from "./../Carousel/Carousel";
import './CareerCard.css';
import {useTranslation} from "react-i18next";

export default function CareerCard({title, lightText, techStack, usedTechStack, description, functions, carousel}) {
    const { t } = useTranslation();
    return (
        <article className={"card"}>
            <h2>{title}</h2>
            <span className="light-text">{lightText}</span>
            {techStack && usedTechStack && <SkillsList techStack={techStack} includes={usedTechStack} showName={true} logoSize="small"/>}
            <p><div dangerouslySetInnerHTML={{ __html: description }}/></p>
            {
                functions && <>
                    <h4><strong>{t('Some functionalities')}</strong></h4>
                    <ul>
                        {functions.map(item => <li>{item}</li>)}
                    </ul>
                </>
            }

            <Carousel carousel={carousel} />


        </article>
    )
}
