import React from "react";

import './HireMe.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export default function HireMe() {
	const { t } = useTranslation();
	return <div className={'hire-content'}>
			<h1><FontAwesomeIcon icon="fa-brands fa-telegram-plane" size="2xl" style={{color: "#282c34"}} /> Keep In Touch</h1>
			<p>I am specialized in Backend development</p>
			<p>Feel free to get in touch and talk more about your projects</p>
			<p>{t('Send me a WhatsApp!')}</p>
		</div>;
}