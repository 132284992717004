import React, {useState} from "react";
import {Tab} from "./Tab";

export const Tabs = ({ children }) => {
	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<div className="tabs-wrapper">
			<div className="tabs-headers">
				{React.Children.map(children, (child, index) => (
					<Tab
						key={index}
						label={child.props.label}
						active={activeIndex === index}
						onClick={() => setActiveIndex(index)}
					/>
				))}
			</div>

			<div className="tabs-content">
				{React.Children.toArray(children)[activeIndex]}
			</div>
		</div>
	);
};