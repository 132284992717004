import React from "react";

import Section from "../Section/Section";

export default function Education(props) {

    return (
        <Section title="Education">
            <p>
                Currently studying a B.Sc. in Information Systems Engineering at
                <a href={"https://www.frba.utn.edu.ar/sistemas/ing-en-sistemas-de-informacion/"}> UTN FRBA </a>
                (Ingenieria en Sistemas de Información)
            </p>
        </Section>
    );
}