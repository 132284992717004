import React from 'react';
import './SocialLinks.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faLinkedin, faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

function SocialLinks({ orientation = 'vertical' }) {
    return (
        <nav id="social-links" className={"social-links-" + orientation}>
            <ul>
                <li>
                    <a href="https://linkedin.com/in/lautagui" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                        <span>LinkedIn</span>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/thisbar" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGithub} size="lg" />
                        <span>GitHub</span>
                    </a>
                </li>
                <li>
                    <a href="mailto:lautaro@lautaroaguirre.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                        <span>E-mail</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/@lautaro_dev" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutube} size="lg" />
                        <span>YouTube</span>
                    </a>
                </li>
                <li>
                    <a href="https://linktr.ee/lautagui" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLink} size="lg" />
                        <span>Linktr.ee</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default SocialLinks;
